<template>
    <div class="s_popover">
            <div class="inner_popover">
                <button @click="openModal('comment-modify-modal')" class="popover_btn">수정</button>
            </div>
            <div class="inner_popover">
                <button @click="runDeleteFunc('runDeleteFunc')" class="popover_btn">삭제</button>
            </div>
            <div  class="inner_popover">
                <button @click="runDeclarationFunc('runDeleteFunc')" class="popover_btn">신고</button>
            </div>
    </div>
</template>

<script>
    import EventBus from "@/utils/event-bus";

    export default {
        name: "LetterCommentPopover",
        mixins: [],
        components: {},
        props: {},
        data() {
            return {
                LoadBtnText: '',
                ExportBtnText: '',
                $this: null,
            }
        },
        beforeRouterEnter() {
        },
        created() {
            this.$this = this.$options.$this;
        },
        mounted() {
            EventBus.$emit('pushBackFunc', this.BackEvt);
        },
        beforeDestroy() {
            EventBus.$emit('popBackFunc');
        },
        destroyed() {
        },
        computed: {},
        methods: {
            BackEvt() {
                this.$ionic.popoverController.dismiss();
            },
            openModal(modal) {
                this.$ionic.popoverController.dismiss().then(res => {
                    this.$this.$modal.show(modal)
                });

            },
            movePage(url) {
                this.$ionic.popoverController.dismiss().then(res => {
                    this.$this.$router.push(url);
                });
            },
            runDeleteFunc() {
                this.$ionic.popoverController.dismiss().then(res => {
                    this.$options.deleteFunc();
                });
            },
            runDeclarationFunc() {
                this.$ionic.popoverController.dismiss().then(res => {
                    this.$options.declarationFunc();
                })
            },
        },
        watch: {},
    }
</script>

<style scoped>

</style>
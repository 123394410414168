<template>
    <div  class="comment_list empty">
        작성된 댓글이 없습니다.<br/>
        작성한 댓글은 어린이들의 소중한 용기와 희망이 됩니다.
    </div>
</template>
<script>
    import {mapGetters, mapState} from "vuex";
    import EventBus from "@/utils/event-bus";
    import VuxAlert from "@/mixins/vux-alert";
    import LetterCommentPopover from "@/components/popover/LetterCommentPopover";
    import BoardLikeComponent from "@/components/board/BoardLikeComponent";

    export default {
        name: 'CommentListDefaultLayout',
        mixins: [VuxAlert],
        components: {BoardLikeComponent},
        props: {
            commentData: {
                // type: Array,
                default: () => {
                    return []
                },
            },
            setData: {
                type: Function,
                default: () => {
                },
            },
            deleteComment: {
                type: Function,
                default: () => {
                },
            },
            createDeclaration: {
                type: Function,
                default: () => {
                },
            }
        },
        data() {
            return {
                noData: false,
                reply :3
            }
        },
        beforeRouterEnter() {
        },
        beforeCreate() {
        },
        created() {

        },
        beforeMount() {
        },
        mounted() {

        },
        beforeUpdate() {
        },
        updated() {
        },
        beforeRouterLeave() {
        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {
            ...mapState({
            }),
        },
        methods: {
            returnDate(date) {
                let nowDate = this.$moment().format('YYYY-MM-DD HH:mm:ss');
                if (!date) {
                    return "";
                }
                if (this.$moment(date).isBefore(nowDate, 'date')) {
                    return this.$moment(date).format('YYYY-MM-DD');
                }
                if (this.$moment(date).isBefore(nowDate, 'hours')) {
                    return Number(this.$moment(nowDate).format('HH')) - Number(this.$moment(date).format('HH')) + '시간'
                }
                if (this.$moment(date).isBefore(nowDate, 'minutes')) {
                    return Number(this.$moment(nowDate).format('mm')) - Number(this.$moment(date).format('mm')) + '분'
                }
                if (this.$moment(date).isBefore(nowDate, 'seconds')) {
                    return Number(this.$moment(nowDate).format('ss')) - Number(this.$moment(date).format('ss')) + '초'
                }
                return '1초'
            },
            parentSetData(member) {
                let commentInput = document.querySelector('.write_input');
                commentInput.setFocus();
                this.setData('mentionUser', member);
            },
            deletePassible(comment) {
                if (this.UserInfo.mb_no === this.detail.Writer.mb_no || this.UserInfo.mb_no == comment.Writer.mb_no) {
                    return true;
                }
                return false;
            },
            parentSendData(comment) {
                this.deleteComment(comment.wr_id);
            },
            async openCommentMenu(comment, event = Event) {
                let popover = await this.$ionic.popoverController.create({
                    component: LetterCommentPopover,
                    componentProps: {
                        deleteFunc: () => {this.openDeleteConfirm()},
                        declarationFunc: () => {this.openDeclarationConfirm()},
                        comment: comment,
                        $this: this,
                    },
                    event: event,
                    cssClass: 'comment-popover',
                    translucent: true
                });
                popover.present();
            },
            openDeleteConfirm(comment) {
                this.createConfirm({
                    title: '',
                    content: '정말 삭제하시겠습니까?',
                    confirmText: '확인',
                    cancelText: '취소',


                    confirm: () => {
                        this.createAlert({
                            title: '',
                            content: '삭제완료',
                            btnText: '확인',
                        })
                    }
                })
            },
            openDeclarationConfirm() {
                this.declarateConfirm({
                    confirm: () => {
                        this.createAlert({
                            title: '',
                            content: '신고완료',
                            btnText: '확인',
                        })
                    }
                })
            },
            declaration(comment, reason) {
                this.createDeclaration(comment, reason);
            },
            commentMoreBtn() {

            }

        },
        watch: {},
    }
</script>